<template>
  <CommonPages id="home" classs="home ">
    <div class="top-banner-row">
      <div class="top-banner">
        <div class="banner-title">
          Open AI <br> in your hands
        </div>
        <div class="banner-sub-title">
          SOTA language models for devs, unrestricted
        </div>
        <div class="banner-btn-row">
          <a class="banner-btn-item active" href="https://platform.abacistopia.com/demo/">
            Start Developing
          </a>
          <a class="banner-btn-item " href="https://platform.abacistopia.com/docs/">
            Read Docs
          </a>
        </div>
      </div>
      <img src="@/assets/banner-img.png" alt="banner" class="banner-img">
    </div>

    <div class="building-intro-container pc_padding">
      <div class="building-intro-item-row">
        <div class="building-title strong">
          Build with Abacus API
        </div>
        <div class="coding-row">
          <pre><code ref="code" class="language-json">{{ codeContent }}</code></pre>
        </div>
      </div>

      <div class="building-intro-item-row">
        <div class="building-title">
          Why Abacus AI?
        </div>
        <div class="build-ai-intro-row">
          <div v-for="(data,i) in introSource" :key="`source_${i}`" class="build-ai-intro-item">
            <div class="build-ai-intro-item-icon-row">
              <img :src="data.img" alt="" class="build-ai-intro-img">
            </div>
            <div class="build-ai-intro-title" v-html="data.title" />
            <div class="build-ai-intro-content" v-html="data.content" />
          </div>
        </div>
      </div>

      <div class="building-intro-item-row">
        <div class="building-title">
          What we do?
        </div>
        <div class="product-row ">
          <div class="product-item model">
            <div class="product-title">
              Large Language Models
            </div>
            <div class="product-item-content">
              <div v-for="model in models" :key="model.id" class="model-item">
                <div class="model-name">
                  {{ model.name }}
                  <div :class="`tag tag-${model.tag}`" v-html="model.tagtext" />
                </div>
                <div class="model-description">
                  {{ model.description }}
                </div>
              </div>
            </div>
          </div>
          <div class="product-item service">
            <div class="product-title">
              Chatbot Service
            </div>
            <div class="product-item-content">
              <div v-for="service in services" :key="service.id" class="model-item">
                <div class="model-name">
                  {{ service.name }}
                  <div :class="`tag tag-${service.tag}`" v-html="service.tagtext" />
                </div>
                <div class="model-description">
                  {{ service.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="building-intro-item-row">
        <div class="building-title">
          Our Products
        </div>
        <div class="apps-row ">
          <div v-for="(data,i) in products" :key="`product_${i}`" class="apps-item">
            <img :src="data.img" class="app-logo" alt="">
            <div class="apps-title">
              {{ data.name }}
            </div>
            <div class="apps-content">
              {{ data.desc }}
            </div>
            <div class="download-btn">
              <a v-for="(app,j) in data.downloadfrom" :key="`app_${j}`"
                :class="`download-btn-${app}`" :href="data?.direct[j]">
                <span v-if="app==3">
                  Visit Site
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="building-intro-item-row contact">
        <div class="building-title ">
          Contact us
        </div>
        <div class="contact-row">
          <div class="contact-text">
            Use Abacus for free as long as you like or get full access with any of our paid plans.
          </div>
          <div class="contact-btn-row">
            <a class="contact-btn-item active" href="mailto:sales@abacistopia.com">
              Get in touch
            </a>
          </div>
        </div>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import CommonPages from '@/pages/components/pc_common.vue'
import '@/css/main.scss'
import '@/css/common.scss';

export default {
  name: 'Home',
  components: {
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      codeContent: `curl --location --request POST 'https://api.abacistopia.com/v1/modelChat/chat' \\
--header 'Authorization: Bearer XXX' \\
--header 'Content-Type: application/json; charset=utf-8' \\
--data-raw '{
  "chargingModelId": 12,
  "context": [
    {
      "role": "user",
      "content": "Hello"
    }
  ]
}'`,
      models: [
        { id: 1, name: 'Abacus-pro', description: 'Flagship model', tag: 1, tagtext: 'Pro' },
        { id: 2, name: 'Abacus', description: 'Best combination of performance and speed' },
        { id: 3, name: 'Abacus-flash', description: 'Lightweight, fast', tag: 2, tagtext: 'Flash' }
      ],
      services: [
        { id: 1, name: 'Abacus-char', description: 'Role-play chat model', tag: 3, tagtext: 'Chat' },
      ],
      introSource: [
        { img: require('@/assets/perform_1.png'), title: 'Openness & Freedom', content: 'Our unrestricted platform offers full flexibility free from proprietary limitations.' },
        { img: require('@/assets/perform_2.png'), title: 'Performance & Speed', content: 'Our unrestricted platform offers full flexibility free from proprietary limitations.' },
        { img: require('@/assets/perform_3.png'), title: 'Flexible Model Selection', content: 'Varying model sizes let users choose the right fit for any use case from complex to lightweight tasks.' },
        { img: require('@/assets/perform_4.png'), title: 'Cost-Effectiveness', content: 'Affordable AI capabilities without compromis making advanced tech accessible to all.' },
      ],
      products: [
        {
          img: require('@/assets/app-1.png'), name: 'Tarotknows', desc: 'Divination Prediction', downloadfrom: [1], direct: ['https://play.google.com/store/apps/details?id=ai.tarotknows.reader']
        },
        {
          img: require('@/assets/app-2.png'), name: 'MineBrowser', desc: 'An AI powered browser', downloadfrom: [1], direct: ['https://play.google.com/store/apps/details?id=ai.search.browser']
        },
        {
          img: require('@/assets/app-3.png'), name: 'FlowGPT', desc: 'AI text to Video, Bots, Agents', downloadfrom: [3], direct: ['#FlowGPT']
        },
        {
          img: require('@/assets/app-4.webp'), name: 'Murmur', desc: 'Your AI Sanctuary', downloadfrom: [2, 1, 3], direct: ['https://apps.apple.com/us/app/murmur-your-ai-sanctuary/id6618151695', 'https://play.google.com/store/apps/details?id=com.intelligentincubator.whispria&pli=1 ', 'https://murmured.me/']
        },
      ]

      ////////
    };
  },
  metaInfo () {
    return {
      title: 'Abacus AI',
      meta: [
        {
          name: 'description',
          content: 'Abacus AI',
        },
      ],
    };
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },

};
</script>
