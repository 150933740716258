<template>
  <CommonPages id="pricing" classs="pricing ">
    <div class="pricing-container pc_padding">
      <div class="pricing-title">
        Pricing
      </div>
      <div class="pricing-row">
        <div v-for="(price,i) in pricing" :key="`pric_${i}`" class="pricing-item">
          <div class="pricing-name">
            {{ price.name }}
          </div>
          <div class="pricing-func-row">
            <div v-for="(item,j) in price.items" :key="`item_${j}`" class="pricing-func-item">
              <img src="@/assets/Icons.svg" alt=""> {{ item }}
            </div>
          </div>
          <div class="pricing-cast-row">
            <div class="pricing-cast">
              {{ price.price }}
            </div>
            <div class="pricing-cast-unit">
              {{ price.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import CommonPages from '@/pages/components/pc_common.vue'
import '@/css/main.scss'
import '@/css/common.scss';

export default {
  name: 'Pricing',
  components: {
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      pricing: [
        { name: 'Abacus-pro', items: ['Flagship model', '128K context window'], price: '$7.5', unit: '1M tokens' },
        { name: 'Abacus', items: ['Best combination of performance and speed', '128K context window'], price: '$0.15', unit: '1M tokens' },
        { name: 'Abacus-flash', items: ['Lightweight, fast', '128K context window'], price: '$0.02', unit: '1M tokens' },
        { name: 'Abacus-char', items: ['Role-play chat model'], price: '$2.5', unit: '1M tokens' },
      ]

      ////////
    };
  },
  metaInfo () {
    return {
      title: 'Abacus AI',
      meta: [
        {
          name: 'description',
          content: 'Abacus AI',
        },
      ],
    };
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },

};
</script>
