<template>
  <div class="head-bar-container" @click.self="closeMenu">
    <div class="head-bar-row pc_padding">
      <div class="head-bar-name-row cursor" @click="gohome">
        <div class="head-bar-name-icon">
          <img src="@/assets/Logo-long.svg" alt="">
        </div>
        <!-- <div class="head-bar-name">
          <img src="@/assets/Abacus.png" alt="">
        </div> -->
      </div>
      <div v-if="deviceType=='mobile'" class="head-bar-hamber-row cursor " @click="toggleOpenState">
        <img src="@/assets/menu.svg" alt="">
      </div>
      <div v-if="deviceType!='mobile'" class="head-bar-hamber-row  cursor ">
        <router-link to="/">
          <div class="head-bar-hamber-item" :class="{ active: $route.name == 'home' }">
            Overview
          </div>
        </router-link>
        <router-link to="/pricing">
          <div class="head-bar-hamber-item" :class="{ active: $route.name == 'pricing' }">
            Pricing
          </div>
        </router-link>
        <router-link to="#Careers">
          <div class="head-bar-hamber-item">
            Careers
          </div>
        </router-link>
        <router-link to="#news">
          <div class="head-bar-hamber-item">
            News
          </div>
        </router-link>
      </div>
    </div>

    <div v-if="deviceType=='mobile'" class="menu" :class="{ 'menu-open': isOpen }">
      <div class="menu-open-head">
        <div class="menu-open-logo-row">
          <img src="@/assets/Logo.svg" class="logo" alt="">
          <img src="@/assets/Logo-long-w.svg" class="logo-long" alt="">
        </div>
        <img src="@/assets/close.png" class="close" alt="" @click="toggleOpenState">
      </div>
      <ul>
        <li class="cursor">
          <router-link to="/">
            <div class="head-bar-hamber-item">
              Overview
            </div>
          </router-link>
        </li>
        <li class="cursor">
          <router-link to="/pricing">
            <div class="head-bar-hamber-item">
              Pricing
            </div>
          </router-link>
        </li>
        <li class="cursor">
          <router-link to="#Careers">
            <div class="head-bar-hamber-item">
              Careers
            </div>
          </router-link>
        </li>
        <li class="cursor">
          <router-link to="#news">
            <div class="head-bar-hamber-item">
              News
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    gohome () {
      window.location.href = '/'
    },
    toggleOpenState () {
      this.isOpen = !this.isOpen;
    },
    handleClickOutside (event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    closeMenu () {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    goto (where) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (where == 'home') {
        this.$emit('setRequestDomainHome');
      }
      if (where == 'home' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      this.$router.push({ name: where });
    },
  },
}
</script>
