<template>
  <footer>
    <div class="foot-nav-container pc_padding">
      <div class="footer-about">
        <div class="footer-item-title">
          <img src="@/assets/Logo-w.png" alt="">
        </div>
        <div class="footer-item-content">
          Open AI in your hand
        </div>
      </div>
      <div class="footer-side-row">
        <div class="footer-side-resource">
          <div class="footer-item-content resource">
            <a href="/">
              <div class="footer-item-content-item cursor">
                Abacus AI
              </div>
            </a>
            <a href="#API">
              <div class="footer-item-content-item cursor">
                API
              </div>
            </a>
            <a href="#Team">
              <div class="footer-item-content-item cursor">
                Team
              </div>
            </a>
            <a href="#Research">
              <div class="footer-item-content-item cursor">
                Research
              </div>
            </a>
            <a href="#Customers">
              <div class="footer-item-content-item cursor">
                Customers
              </div>
            </a>
          </div>
          <div class="footer-item-content resource">
            <a href="#Privacy">
              <div class="footer-item-content-item cursor">
                Privacy Policy
              </div>
            </a>
            <a href="#Legal">
              <div class="footer-item-content-item cursor">
                Legal Hub
              </div>
            </a>
            <a href="#Whistleblower">
              <div class="footer-item-content-item cursor">
                Whistleblower Policy
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="corner-module pc_padding">
      © Abacistopia 2024. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      deviceType: 'deviceType',
    }),
  },
  methods: {
    goto (where) {
      console.log(this.$route.path);
      this.$router.push({ name: where });
    },
  },
};
</script>
