<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.path" />
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: "App",
  data () {
    return {
      isMobile: false
    };
  },
  created () {
    this.checkDeviceType();
  },
  methods: {
    checkDeviceType () {
      const userAgent = navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      this.isMobile = mobileRegex.test(userAgent);
      this.$store.commit('setDeviceType', this.isMobile ? 'mobile' : "pc")

    }
  },
};
</script>
